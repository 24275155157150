
import router from '@/router'
import { AppActions, AppMutation } from '@/store/types'
import { CookiesToken } from '@/types/CookiesToken'
import { getCookieValue } from '@/utils'
import {
    defineComponent,
    ref,
    inject,
    Emitter,
    onBeforeMount,
    onMounted,
    computed
} from 'vue'
import { useStore } from '@/store'
import { EventBuss, languageOptions } from '@/types/global'
import { useI18n } from 'vue-i18n'
import { iconArrowDown, iconLines, IService } from '@/components/icons'

export default defineComponent({
    name: 'select-server-line',
    components: {
        'icon-arrow-down': iconArrowDown,
        'icon-lines': iconLines,
        IService
    },
    setup() {
        const emitter = inject('emitter') as Emitter

        const store = useStore()
        const { t, locale } = useI18n()

        const line1 = ref<boolean>()
        const line2 = ref<boolean>()
        const line3 = ref<boolean>()
        const serverStr = ref<string>('')

        const langArr = ref(languageOptions)
        const showLanguageOptions = ref(false)
        let cs = 0

        const info = onBeforeMount(() => {
            window.sessionStorage.clear()
            if (lang.value && lang.value !== '') locale.value = lang.value
        })

        onMounted(() => {
            pingServer(1)
            pingServer(2)
            pingServer(3)
        })

        const language = computed((): string => {
            const result = langArr.value.find((x) => x.code === lang.value)
            return result?.name || ''
        })

        const clickLine = (line: number) => {
            if (line === 1 && !line1.value) return
            else if (line === 2 && !line2.value) return
            else if (line === 3 && !line3.value) return

            store.commit(AppMutation.APP_SET_LINE, line)
            router.push({ name: 'login' })
        }

        const isValidSevice = (urlStr: string): boolean => {
            const pattern = new RegExp(
                '^((ft|htt)ps?:\\/\\/)?' + // protocol
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
                    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                    '(\\:\\d+)?' + // port
                    '(\\/[-a-z\\d%@_.~+&:]*)*' + // path
                    '(\\?[;&a-z\\d%@_.,~+&:=-]*)?' + // query string
                    '(\\#[-a-z\\d_]*)?$',
                'i'
            )
            return pattern.test(urlStr)
        }

        const enterLogin = () => {
            if (serverStr.value.length > 0) {
                let url = serverStr.value.replace(/\/$/, '')

                let appendHttp = true

                if (url.startsWith('https://')) {
                    appendHttp = false
                } else if (url.startsWith('http://')) {
                    appendHttp = false
                }

                if (appendHttp) url = `http://${url}`

                if (!isValidSevice(url)) {
                    emitter.emit(
                        EventBuss.TOAST_MESSAGE,
                        t('serverselection.lineerror')
                    )
                    return
                }

                store.commit(AppMutation.SHOW_LOADING)

                const urlFinal = url + '/ctrl/dList?safeUrl=QWE~!@wwCa99AS'

                store
                    .dispatch('pingServer', { line: -1, url })
                    .then(() => {
                        store.commit(AppMutation.HIDE_LOADING)
                        store.commit(AppMutation.APP_SET_LINE, -1)
                        store.commit(AppMutation.APP_SET_LINE_STR, url)
                        router.push({ name: 'login' })
                    })
                    .catch(() => {
                        store.commit(AppMutation.HIDE_LOADING)
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            t('serverselection.lineerror')
                        )
                    })
            }
        }

        const pingServer = async (line: number) => {
            const params = { line, url: '' }
            store
                .dispatch(AppActions.PING_SERVER, params)
                .then(() => {
                    if (line === 1) line1.value = true
                    else if (line === 2) line2.value = true
                    else if (line === 3) line3.value = true
                })
                .catch(() => {
                    if (line === 1) line1.value = false
                    else if (line === 2) line2.value = false
                    else if (line === 3) line3.value = false
                })
        }

        const contactService = () => {
            window.open(process.env.CUSTUMER_SERVICE, '_blank')
        }

        const selectLanguage = (str: string) => {
            store.commit(AppMutation.SET_LANG, str)
            locale.value = str
            showLanguageOptions.value = false
        }

        const lang = computed(() => store.getters['lang'])
        const ipvnotice = computed(() => store.getters['ipvnotice'])

        return {
            line1,
            line2,
            line3,
            serverStr,
            langArr,
            lang,
            showLanguageOptions,
            language,
            ipvnotice,
            t,
            selectLanguage,
            clickLine,
            enterLogin,
            contactService
        }
    }
})
